<template>
  <v-container
    fluid
    tag="section"
  >
    <base-material-card
      icon="mdi-alarm-multiple"
      title="Wettkämpfe"
      color="red"
    >
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="filter"
            label="Filter"
          />
        </v-col>
        <v-col v-if="!$vuetify.breakpoint.mdAndDown" cols="12">
          <v-range-slider
            v-model="date"
            :tick-labels="dates_format"
            :value="dates"
            min="0"
            :max="dates.length - 1"
            ticks="always"
            tick-size="4"
          />
        </v-col>
        <v-col v-if="$vuetify.breakpoint.mdAndDown" cols="6">
          <v-select label="Wettkämpfe anzeigen von" @change="(a) => set(0, a)" :value="dates_format[date[0]]" :items="dates_format" />
        </v-col>
        <v-col v-if="$vuetify.breakpoint.mdAndDown" cols="6">
          <v-select label="Wettkämpfe anzeigen bis" @change="(a) => set(1, a)" :value="dates_format[date[1]]" :items="dates_format" />
        </v-col>
        <v-col cols="12">
          <p>Klicken Sie auf eine Begegnung um die Live-Anzeige bzw. das Endergebnis anzuzeigen.</p>
        </v-col>
      </v-row>

      <EventTable
        :events="events_filtered"
        :filter="filter"
      />
    </base-material-card>
  </v-container>
</template>

<script>
import Vue from 'vue'
import gql from 'graphql-tag'
import { deleteQuery, useGraphQL } from '@/plugins/graphql'

const query = `
  _id
  ... on StbM2021League {
    name
    shortName
    order
    events {
      _id
      name
      startDate
      publicComment
      status
      teams {
        team { _id name }
        home
        result { final score gp tp }
      }
      venue {
        _id
        name
        address { streetAddress streetNumber postalCode addressLocality }
      }
      mode
    }
  }
`

export default {
  name: 'start',

  components: {
    EventTable: () => import('../components/EventTable')
  },

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    EventFind: [],
    filter: '',
    date: [0, 0]
  }),

  computed: {
    events () {
      return this.EventFind.reduce((acc, curr) => {
        acc.push(...curr.events.map(e => ({
          ...e,
          liga: curr,
          mannschaften: e.teams?.slice().sort((a, b) => {
            if (a.home !== b.home) return a.home ? -1 : 1
            if (a.result?.final !== b.result?.final) return a.result?.final > b.result?.final ? -1 : 1
            return a.order < b.order ? -1 : 1
          }).map(t => t.team.name) || [],
          ergebnisse: e.teams?.slice().sort((a, b) => {
            if (a.home !== b.home) return a.home ? -1 : 1
            if (a.result?.final !== b.result?.final) return a.result?.final > b.result?.final ? -1 : 1
            return a.order < b.order ? -1 : 1
          }).map(t => t.result) || []
        })))
        return acc
      }, []).sort((a, b) => {
        if (a.startDate !== b.startDate) return a.startDate < b.startDate ? -1 : 1
        if (a.liga.order !== b.liga.order) return a.liga.order < b.liga.order ? -1 : 1
      })
    },
    events_filtered () {
      const start = this.dates[this.date[0]] + ' 00:00:00'
      const end = this.dates[this.date[1]] + ' 23:59:39'

      return this.events.filter(e => e.startDate >= start && e.startDate <= end)
    },
    eventgroups () {
      return Object.values(this.events.reduce((acc, curr) => {
        if (!acc[curr.startDate]) acc[curr.startDate] = []
        acc[curr.startDate].push(curr)
        return acc
      }, {})).map(g => {
        return {
          startDate: g[0].startDate,
          leagues: g.sort((a, b) => a.lorder < b.lorder ? -1 : 1)
        }
      })
    },
    dates () {
      if (this.events.length === 0) return ['']
      return this.events.map(e => e.startDate?.split(' ')?.[0]).sort().filter((d, i, arr) => !arr.find((d2, j) => j < i && d === d2))
    },
    dates_format () {
      return this.dates.map(d => this.$options.filters.dateformat(d, 'DD.MM.'))
    }
  },

  methods: {
    set (i, val) {
      const index = this.dates_format.findIndex(d => d === val)
      if (index === -1) return
      Vue.set(this.date, i, index)
    }
  },

  watch: {
    dates () {
      const now = (new Date()).toISOString().split('T')[0]

      const index = this.dates.findIndex(d => d >= now)
      if (index !== -1) {
        if (this.dates[index] === now) {
          this.date = [Math.max(index, 0), this.dates.length - 1]
        } else {
          this.date = [Math.max(index - 1), this.dates.length - 1]
        }
      } else {
        this.date = [this.dates.length - 1, this.dates.length - 1]
      }
    }
  },

  apollo: {
    EventFind: {
      query: gql`
          query($parent: UUID) { EventFind(parent: $parent) { ${query} }}
        `,
      variables () {
        return {
          parent: this.id
        }
      }
    },
    $subscribe: {
      EventUpdate: {
        query: gql`
          subscription($parent: UUID) { EventUpdate(parent: $parent) { ${query} }}
        `
      },
      EventDelete: {
        query: gql`
          subscription($parent: UUID) { EventDelete(parent: $parent) }
        `,
        result (id) {
          deleteQuery('EventFind', 'EventDelete', this.EventFind, id)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
